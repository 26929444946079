import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Offers from '@rushplay/offers'

import * as Constants from './constants'
import * as Theming from './theming'
import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import * as Utils from './utils'
import { Divider } from './divider'
import { RadioButton } from './radio-button'

function Picker(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      `offer-picker.${props.id}.title`,
      `offer-picker.${props.id}.img-src`,
      `offer-picker.rewards.title`,
      ...props.rewards.map(reward => reward.key),
      'offer-picker.entice-greater-deposit',
      'offer-picker.minimum-deposit-amount',
      'offer-picker.terms-and-conditions.link',
      'offer-picker.select-offer',
    ],
    [props.id, props.rewards]
  )

  const selected = props.selectedOfferId === props.id

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        borderRadius={0}
        p={1}
        color="g-text"
        lineHeight="1.5"
      >
        <Common.Flex>
          <RadioButton
            checked={selected}
            disabled={props.disabled}
            variant="radio"
            name="offer-picker"
            onChange={() => props.onChange(props.id)}
          />

          <Common.Box
            flexGrow="1"
            color={props.disabled ? 'text-disabled' : 'g-text'}
          >
            <Common.Box
              fontWeight="bold"
              fontSize={2}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {translate(
                `offer-picker.${props.id}.title`,
                props.translationVariables
              )}
            </Common.Box>
          </Common.Box>
          <Common.Box flexShrink="0">
            <Common.Box
              width="60px"
              height="60px"
              style={{
                transition: 'filter 200ms ease-in-out',
                filter:
                  props.disabled || (props.selectedOfferId && !selected)
                    ? 'grayscale(1)'
                    : 'grayscale(0)',
              }}
              backgroundImage={`url(${translate(
                `offer-picker.${props.id}.img-src`
              )})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              borderRadius="9999px"
              ml={0}
            />
          </Common.Box>
        </Common.Flex>

        <Common.Space px={0} py={1}>
          <Divider />
        </Common.Space>

        <Common.Box fontSize={2} fontWeight="bold">
          {translate(`offer-picker.rewards.title`)}
        </Common.Box>

        <Common.Box pt={0}>
          {props.rewards.map(reward => (
            <Common.Box
              color={
                !props.disabled && reward.value
                  ? 'offer-picker-info'
                  : 'text-disabled'
              }
              fontWeight={!props.disabled && reward.value && 'bold'}
              fontSize={2}
              key={reward.key}
              display="flex"
            >
              <Common.Box fontSize={2} pr={0}>
                {!props.disabled && reward.value ? (
                  <Icons.CheckBold />
                ) : (
                  <Icons.Clear />
                )}
              </Common.Box>
              {translate(reward.key, reward.translationVariables)}
            </Common.Box>
          ))}
        </Common.Box>

        {!props.disabled && props.depositLeftForFullRewards > 0 && (
          <Common.Box fontSize={2} color="offer-picker-info" pt={0}>
            <Common.Box pr={0} display="inline-box">
              <Icons.Info />
            </Common.Box>
            {translate('offer-picker.entice-greater-deposit', {
              depositLeftForFullRewardsCents: props.depositLeftForFullRewards,
            })}
          </Common.Box>
        )}

        {props.disabled && (
          <Common.Box fontSize={3} color="danger" pt={0}>
            <Common.Box pr={0} display="inline-box">
              <Icons.Error />
            </Common.Box>
            {translate('offer-picker.minimum-deposit-amount', {
              minDepositAmount: props.translationVariables.minDepositAmount,
            })}
          </Common.Box>
        )}

        <Common.Box
          fontSize={1}
          style={{ textDecoration: 'underline' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt={1}
        >
          <ReactRouter.Link to="/terms-and-conditions/welcome-offer">
            {translate('offer-picker.terms-and-conditions.link')}
          </ReactRouter.Link>
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

Picker.propTypes = {
  disabled: PropTypes.bool,
  depositLeftForFullRewards: PropTypes.number,
  id: PropTypes.number,
  progress: PropTypes.number,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
      translationVariables: PropTypes.object,
    })
  ),
  selectedOfferId: PropTypes.number,
  translationVariables: PropTypes.object,
  onChange: PropTypes.func,
}

export function OfferPicker(props) {
  const translate = Herz.I18n.useTranslate()

  const amountField = Forms.useField('#/properties/amount', {
    noRegister: true,
  })
  const amountCents = amountField.value ? amountField.value : 0
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT

  const offers = ReactRedux.useSelector(state =>
    CombinedSelectors.getCalculatedOffers(state, { amountCents })
  )

  function handleChange(offerId) {
    if (offerId && R.not(R.equals(offerId, props.selectedOfferId))) {
      props.onChangeOfferId(offerId)
    } else if (offerId === undefined) {
      props.onChangeOfferId()
    } else {
      props.onChangeOfferId(null)
    }
  }

  if (R.isEmpty(offers) || R.not(isDeposit)) {
    return null
  }

  return (
    <Common.Space pt={2}>
      <Common.Text
        fontSize={4}
        textAlign="center"
        fontWeight="bold"
        fontFamily="head"
      >
        {translate('offer-picker.select-offer')}
      </Common.Text>
      <Common.Box pt={2} display="grid" gridGap={0}>
        {R.map(
          offer => (
            <Picker
              disabled={amountCents < R.head(offer.rangeLimits)}
              key={offer.id}
              selectedOfferId={props.selectedOfferId}
              progress={offer.offerProgressPercent}
              depositLeftForFullRewards={
                offer.minDepositForFullRewards - amountCents
              }
              rewards={Offers.getOfferRewards(Utils.toArray(offer))}
              translationVariables={{
                bonusMoneyPercent: offer.bonusMoneyPercent,
                bonusMoneyPercentMaxCents: offer.bonusMoneyPercentMaxCents,
                minDepositAmount: R.head(offer.rangeLimits),
              }}
              id={offer.id}
              onChange={handleChange}
            />
          ),
          offers
        )}

        <Theming.Alternative>
          <Common.Box
            backgroundColor="g-bg"
            borderRadius={0}
            p={1}
            color="g-text"
          >
            <Common.Flex>
              <RadioButton
                checked={props.selectedOfferId === null}
                variant="radio"
                name="offer-picker"
                onChange={() => {
                  handleChange(null)
                }}
              />

              <Common.Box
                alignSelf="center"
                flexGrow="1"
                fontWeight="bold"
                fontSize={2}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {translate(`offer-picker.select-none`)}
              </Common.Box>
            </Common.Flex>
          </Common.Box>
        </Theming.Alternative>
      </Common.Box>
    </Common.Space>
  )
}

Herz.I18n.Loader.preload(
  [
    `offer-picker.select-none`,
    `offer-picker.rewards.title`,
    `offer-picker.select-offer`,
  ],
  OfferPicker
)

OfferPicker.propTypes = {
  selectedOfferId: PropTypes.number,
  transactionType: PropTypes.string.isRequired,
  onChangeOfferId: PropTypes.func,
}

// for @loadable/components
export default OfferPicker
