import * as React from 'react'

import * as Cookies from '../cookies-module'
import { LoginPage } from '../login-page'

import { LandingPage as Component } from './landing-page'

export function LandingPage(props) {
  const [returningPlayer] = Cookies.useCookie('returning_player')

  if (returningPlayer) {
    return <LoginPage />
  }

  return <Component {...props} />
}

// for @loadable/components
export default LandingPage
