import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

export function ProgressBar(props) {
  const translate = Herz.I18n.useTranslate()

  return (
    <Common.Box display="flex" pb={1}>
      {R.times(active => {
        return (
          <Common.Box
            key={active}
            display="flex"
            flexDirection="column"
            flexGrow="1"
            opacity={active === props.step ? '1' : '0.5'}
            style={{ transition: 'opacity 0.2s ease-in' }}
          >
            <Common.Box
              flexGrow="1"
              backgroundColor="registration-progress-background"
              borderRadius="2px"
              mx="2px"
              textAlign="center"
              fontSize={0}
              fontWeight="bold"
              padding="4px"
            >
              {translate('registration.progress.step', {
                step: active + 1,
              })}
            </Common.Box>
            <Common.Box
              alignSelf="center"
              width="0px"
              height="0px"
              borderLeft="6px solid transparent"
              borderRight="6px solid transparent"
              borderTop="6px solid"
              borderTopColor="registration-progress-background"
              opacity={active === props.step ? '1' : '0'}
            />
          </Common.Box>
        )
      }, props.steps)}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(['registration.progress.step'], ProgressBar)

ProgressBar.propTypes = {
  steps: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
}

// for @loadable/components
export default ProgressBar
