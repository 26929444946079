import * as R from 'ramda'

import * as Herz from '@rushplay/herz'
/**
 * @param {String} key statistic identifier
 * @param {Object} statistics
 * @param {'live-casino'|'casino'} type catalog type
 */
export function parseStatistic(key, game, type) {
  const data = {
    label: Herz.Utils.Strings.toKebabCase(key),
    value: game[key],
  }

  switch (key) {
    case 'betBehind': {
      if (game.seatsAvailable === 0 && game.betBehind) {
        return R.mergeDeepRight(data, {
          key: 'live-casino.bet-behind-available',
          format: 'translate',
          value: null,
        })
      }
      break
    }

    case 'seatsAvailable': {
      if (game.seatsAvailable > 0 && R.not(game.betBehind)) {
        return data
      }
      break
    }

    case 'jackpotValue': {
      return R.mergeDeepRight(data, {
        value: game[key],
        format: 'jackpot',
        currency: game.jackpotCurrency,
      })
    }
    case 'seatsTotal': {
      if (game.seatsTotal > 0) {
        return data
      }
      break
    }

    case 'dealerName': {
      if (R.not(R.isNil(game.dealerName))) {
        return data
      }
      break
    }

    case 'minBetCents':
    case 'maxBetCents':
    case 'biggestWinCents': {
      if (type === 'live-casino') {
        return R.mergeDeepRight(data, {
          value: game[key] / 100,
          format: 'live-casino-currency',
        })
      } else {
        return game[key] > 0
          ? R.mergeDeepRight(data, { value: game[key], format: 'currency' })
          : null
      }
    }

    case 'releaseDate': {
      return R.mergeDeepRight(data, { value: game[key], format: 'date' })
    }

    case 'feature': {
      return R.length(game[key]) > 0
        ? R.mergeDeepRight(data, {
            key: `game-info.feature.${Herz.Utils.Strings.toKebabCase(
              game[key]
            )}`,
            format: 'translate',
          })
        : null
    }

    case 'volatility': {
      return R.mergeDeepRight(data, {
        key: `game-info.volatility.${Herz.Utils.Strings.toKebabCase(
          game[key]
        )}`,
        format: 'translate',
      })
    }

    default: {
      return data
    }
  }
}
