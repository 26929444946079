import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import { ConditionalGameTilesTypes } from '../constants'

const PickedGamesPage = loadable(() => import('../picked-games-page'))
const PickedGamesPicker = loadable(() => import('../picked-games-picker'))

export class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    props.onFetch()
  }

  handleEdit(idx) {
    this.props.onUpdateView(
      R.isNil(idx) ? 'picked-games-page' : 'picked-games-picker'
    )
    this.props.onCustomizeGame(idx)
  }

  componentDidUpdate(prevProps) {
    if (this.props.view != prevProps.view) {
      this.props.onScrollToTop()
    }
  }

  handleUpdate(id) {
    const game = R.includes(id, ConditionalGameTilesTypes)
      ? { type: id }
      : { type: 'fixed-game', game_id: id }
    const games = R.adjust(this.props.gameIndex, () => game, this.props.games)
    this.props.onUpdatePickedGames(games)
    this.props.onUpdateView('picked-games-page')
  }

  render() {
    return R.equals(this.props.view, 'picked-games-page') ? (
      <common.Box
        flexBasis="0"
        flexGrow="1"
        flexShrink="1"
        overflowX="hidden"
        padding={[1, 2]}
      >
        <PickedGamesPage
          gameIndex={this.props.gameIndex}
          games={this.props.games}
          hideCustomizeButton={this.props.isAllGamesUnset}
          loading={this.props.loading}
          onEdit={this.handleEdit}
          onScrollToTop={this.props.onScrollToTop}
        />
      </common.Box>
    ) : (
      <PickedGamesPicker
        selectedGames={this.props.gamesIds}
        onSelect={this.handleUpdate}
        onCancel={() => this.handleEdit(null)}
      />
    )
  }
}

Dashboard.defaultProps = {
  view: 'picked-games-page',
}

Dashboard.propTypes = {
  gameIndex: PropTypes.number,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      game: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
    })
  ),
  gamesIds: PropTypes.array,
  isAllGamesUnset: PropTypes.bool,
  loading: PropTypes.bool,
  view: PropTypes.string,
  onUpdateView: PropTypes.func.isRequired,
  onCustomizeGame: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onUpdatePickedGames: PropTypes.func.isRequired,
  onScrollToTop: PropTypes.func,
}
