import * as React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { DynamicImage } from './dynamic-image'
import { GameTileLoading } from './game-tile-loading'
import { useImgproxyUrl } from './use-imgproxy-url'

const Wrapper = styled('div', {
  shouldForwardProp: Common.noneOf([
    'alignBottom',
    'backgroundSrc',
    'overlaySrc',
  ]),
})`
  position: relative;
  user-select: none;
  height: 140px;
  width: 140px;
  ${props =>
    css({
      backgroundOrigin: 'content-box, border-box',
      backgroundPosition: props.alignBottom
        ? 'bottom center, center'
        : 'center',
      borderRadius: 0,
      paddingTop: 0,
      px: 0,
    })}
  ${props => {
    if (props.backgroundSrc == null) {
      return css({
        backgroundColor: 'card-background',
        backgroundImage: `url(/images/default-tile-background.svg)`,
        backgroundSize: '40px',
        backgroundRepeat: 'repeat',
      })
    }

    return css({
      backgroundImage: `url(${props.overlaySrc}), url(${props.backgroundSrc})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain, cover',
    })
  }}
`

export function GameTileThumbnail(props) {
  const [backgroundSrc] = useImgproxyUrl(props.backgroundUrl, {
    extension: 'jpg',
    resizingWidth: 140,
  })

  const [overlaySrc] = useImgproxyUrl(props.backgroundOverlayUrl, {
    resizingType: 'fit',
    resizingWidth: 140,
  })

  return (
    <LazyLoad
      once
      resize
      offset={200}
      placeholder={
        <GameTileLoading title={props.gameName || 'Loading'} height="140px" />
      }
    >
      <Wrapper
        data-testid={`game-tile-thumbnail.${Herz.Utils.Strings.toKebabCase(
          props.gameName
        )}`}
        backgroundSrc={backgroundSrc}
        overlaySrc={overlaySrc}
        alignBottom={props.backgroundOverlayImageAlignment === 'bottom'}
      >
        <Common.Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          {props.titleUrl && (
            <DynamicImage
              alt={props.gameName}
              resizingWidth={124}
              resizingType="fit"
              source={props.titleUrl}
              pb={[0]}
              mt="auto"
              maxWidth="100%"
            />
          )}
        </Common.Box>
      </Wrapper>
    </LazyLoad>
  )
}

GameTileThumbnail.propTypes = {
  backgroundUrl: PropTypes.string,
  backgroundOverlayUrl: PropTypes.string,
  backgroundOverlayImageAlignment: PropTypes.string,
  gameName: PropTypes.string.isRequired,
  titleUrl: PropTypes.string,
}
