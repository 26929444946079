import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from './configuration'
import { DynamicTranslationImage } from './dynamic-image'
import { GameStatistics } from './game-statistics.js'
import { HtmlContent } from './html-content'
import { WelcomeOfferModeSelector } from './welcome-offer-mode-selector'

const SvgText = styled.text`
  ${css({
    fontFamily: 'head',
  })}
`
const previewIds = [0, 1, 2, 3]

export function WelcomeOfferGameInfo(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      ...previewIds.map(id => `featured-game.${props.gameKey}.${id}`),
      'featured-game-image',
      'featured-game-text',
      'featured-game-details',
      props.description,
    ],
    []
  )
  const [previewId, setPreviewId] = React.useState(1)
  const hasWebpSupport = ReactRedux.useSelector(state =>
    Configuration.getHasWebpSupport(state.configuration)
  )

  return (
    <Common.Box display="flex" flexWrap="wrap" justifyContent="center">
      <Common.Box
        display="flex"
        flexDirection="column"
        p={1}
        width={['100%', '35%', null, null, '40%']}
        flex="1 1 auto"
        minWidth="320px"
        maxWidth="650px"
      >
        <DynamicTranslationImage
          mb={1}
          borderRadius={0}
          resizingWidth={[350, 450, 550]}
          source={translate(`featured-game.${props.gameKey}.${previewId}`)}
          alt={props.gameKey}
          width="100%"
          extension={hasWebpSupport ? 'webp' : 'png'}
        />
        <Common.Box
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gridGap={1}
        >
          {previewIds.map(id => (
            <Common.Box
              key={id}
              opacity={id === previewId ? 1 : 0.3}
              borderStyle="solid"
              borderWidth="2px"
              borderColor={id === previewId ? '#fff' : 'transparent'}
              onClick={() => setPreviewId(id)}
            >
              <DynamicTranslationImage
                borderRadius={0}
                resizingWidth={100}
                source={translate(`featured-game.${props.gameKey}.${id}`)}
                alt={`preview-${id}`}
                width="100%"
                extension={hasWebpSupport ? 'webp' : 'png'}
              />
            </Common.Box>
          ))}
        </Common.Box>
      </Common.Box>
      <Common.Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        p={1}
        width={['100%', '55%']}
        flex="2 1 auto"
      >
        <Common.Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-start"
          pb={2}
          width="100%"
        >
          <Common.Box display="flex">
            <Common.Box
              pr={0}
              as="img"
              src={translate('featured-game-image')}
              alt="featured-game-image"
            />
            <svg viewBox="0 0 300 20" width="300px" height="20px">
              <defs>
                <linearGradient
                  id="landing-page-featured-text-gradient"
                  x1="0"
                  x2="100%"
                  y1="0"
                  y2="100%"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0%" stopColor="#f2f17a" />
                  <stop offset="20%" stopColor="#fe724e" />
                </linearGradient>
              </defs>
              <SvgText
                fill="url(#landing-page-featured-text-gradient)"
                x="0"
                y="75%"
                fontSize="14"
              >
                {translate('featured-game-text')}
              </SvgText>
            </svg>
          </Common.Box>
          <Common.Box
            pt={1}
            pb={0}
            fontSize={6}
            fontWeight="bold"
            fontFamily="head"
            color="g-text"
          >
            {props.title}
          </Common.Box>
          <Common.Text
            fontSize={1}
            fontFamily="head"
            color="g-text"
            opacity="0.8"
          >
            {props.provider}
          </Common.Text>
          <Common.Box
            pt={1}
            pb={0}
            fontSize={3}
            fontWeight={500}
            color="g-text"
            lineHeight="1.8"
            opacity="0.8"
          >
            {translate('featured-game-details')}
          </Common.Box>
        </Common.Box>
        <GameStatistics
          catalogType="casino"
          gameId={props.gameId}
          jackpotValue={props.jackpotValue}
          feature={props.feature}
          rtp={props.rtp}
          volatility={props.volatility}
          biggestWinCents={props.biggestWinCents}
          minBetCents={props.minBetCents}
          maxBetCents={props.maxBetCents}
          dealerName={props.dealerName}
          seatsTotal={props.seatsTotal}
          seatsAvailable={props.seatsAvailable}
          betBehind={props.betBehind}
          releaseDate={props.releaseDate}
        />
        {translate(props.description) && (
          <Common.Box
            as="p"
            color="g-text"
            fontWeight="bold"
            fontSize={2}
            textAlign="center"
            pb={2}
          >
            <HtmlContent html={{ __html: translate(props.description) }} />
          </Common.Box>
        )}
        <Common.Box display="flex" py={2} justifyContent="center">
          <WelcomeOfferModeSelector
            gameId={props.gameId}
            gameKey={props.gameKey}
          />
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

WelcomeOfferGameInfo.propTypes = {
  gameKey: PropTypes.string,
  backgroundUrl: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  provider: PropTypes.string,
  titleUrl: PropTypes.string,
  gameId: PropTypes.string,
  jackpotValue: PropTypes.number,
  feature: PropTypes.string,
  rtp: PropTypes.string,
  volatility: PropTypes.string,
  biggestWinCents: PropTypes.number,
  minBetCents: PropTypes.number,
  maxBetCents: PropTypes.number,
  dealerName: PropTypes.string,
  seatsTotal: PropTypes.number,
  seatsAvailable: PropTypes.number,
  betBehind: PropTypes.bool,
  releaseDate: PropTypes.string,
}

// for @loadable/components
export default WelcomeOfferGameInfo
