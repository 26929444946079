import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

import * as Configuration from './configuration'
import { DynamicTranslationImage } from './dynamic-image'

const ScrollableRow = styled.div`
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      height: 10px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const items = [1, 2, 3]

export function RegistrationExplanation() {
  const translate = Herz.I18n.useTranslate()
  const hasWebpSupport = ReactRedux.useSelector(state =>
    Configuration.getHasWebpSupport(state.configuration)
  )

  return (
    <Common.Box>
      <Common.Box
        fontWeight="800"
        fontSize={[4, 6]}
        pb={[1, 3]}
        pl={1}
        lineHeight="1.5"
        fontFamily="head"
      >
        {translate('registration-explanation.header')}
      </Common.Box>
      <ScrollableRow>
        <Common.Box display="flex" justifyContent={{ md: 'space-between' }}>
          {items.map(item => (
            <Common.Box
              key={item}
              px={1}
              flex="1"
              minWidth="250px"
              maxWidth="480px"
            >
              <DynamicTranslationImage
                source={translate(`registration-explanation.image-${item}`)}
                alt={`registration-${item}`}
                width="100%"
                resizingWidth={['250', '480']}
                extension={hasWebpSupport ? 'webp' : 'png'}
              />
              <Common.Box
                fontWeight="800"
                fontSize={[3, 5]}
                pt={[1, 2]}
                pb={0}
                lineHeight="1.5"
                fontFamily="head"
              >
                {translate(`registration-explanation.title-${item}`)}
              </Common.Box>
              <Common.Box lineHeight="1.8" opacity="0.8">
                {translate(`registration-explanation.body-${item}`)}
              </Common.Box>
            </Common.Box>
          ))}
        </Common.Box>
      </ScrollableRow>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    ...items.flatMap(item => [
      `registration-explanation.image-${item}`,
      `registration-explanation.title-${item}`,
      `registration-explanation.body-${item}`,
      'registration-explanation.header',
    ]),
  ],
  RegistrationExplanation
)
