import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import * as Promotions from './promotions'
import * as Theming from './theming'
import { QueryDrawer } from './query-drawer'
import { Transaction } from './transaction'
import { usePrev } from './use-prev'

export function Wallet() {
  const location = ReactRouter.useLocation()
  const searchParams = new URLSearchParams(location.search)
  const query = searchParams.get('wallet') || ''
  const prevQuery = usePrev(query)
  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()
  const { authenticated } = Herz.Auth.useSession()

  const [step, setStep] = React.useState(Constants.TransactionStep.Amount)
  const [firstLaunch, setFirstLaunch] = React.useState(true)
  // hack to prevent app crashing when wallet is opened on page refresh
  const isBrowser = Boolean(process.browser)

  // Fetch promotions on wallet open
  // TODO: remove when https://app.clickup.com/t/aprr3d is done
  React.useEffect(() => {
    if (prevQuery !== query && query) {
      dispatch(Promotions.fetch())
    }
  }, [query, prevQuery])

  /** Go out from opted campaigns when wallet closes
   *  or if app loaded for first time without opened wallet
   */
  React.useEffect(() => {
    if ((prevQuery || firstLaunch) && !query) {
      setStep(Constants.TransactionStep.Amount)
      if (firstLaunch) {
        setFirstLaunch(false)
      }
    }
  }, [query, prevQuery, firstLaunch])

  if (!authenticated || !query || !isBrowser) {
    return null
  }

  return (
    <QueryDrawer activeQueryName="wallet" title={translate(`wallet.${query}`)}>
      <Theming.Alternative>
        <Transaction
          onStepChange={setStep}
          step={step}
          transactionType={query}
        />
      </Theming.Alternative>
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  ['wallet.deposit', 'wallet.withdraw', 'wallet.withdrawal'],
  Wallet
)

// for @loadable/component
export default Wallet
