import * as R from 'ramda'
import React from 'react'

import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import { DynamicTranslationImage } from './dynamic-image'

export function RegistrationWelcomeOffer() {
  const translate = Herz.I18n.useTranslate()
  const form = Forms.useFormContext()

  const firstStepValid = R.not(
    R.isEmpty(
      R.pick(
        [
          '#/properties/email',
          '#/properties/password',
          '#/properties/phonenumber',
        ],
        Forms.getFormErrors(form.state)
      )
    )
  )

  return (
    <DynamicTranslationImage
      resizingWidth={280}
      maxWidth="100%"
      source={
        firstStepValid
          ? translate('registration.welcome-offer.1')
          : translate('registration.welcome-offer.2')
      }
    />
  )
}
Herz.I18n.Loader.preload(
  ['registration.welcome-offer.1', 'registration.welcome-offer.2'],
  RegistrationWelcomeOffer
)
