import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Api from '@rushplay/api-client'
import * as Theme from '@rushplay/theme'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Analytics from './analytics'
import * as Promotions from './promotions'
import * as Inventory from './inventory'
import { PromotionCampaigns } from './promotion-campaigns'
import { PromotionInventory } from './promotion-inventory'
import { QueryDrawer } from './query-drawer'
import { getSearchQuery } from './get-search-query'
import { usePrev } from './use-prev'
import { useSafeUpdateQuery } from './use-safe-update-query'

const TabMenuItem = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['active']),
})`
  ${props =>
    css({
      p: 1,
      backgroundColor: props.active ? 'nav-hover' : 'nav',
      '&:hover': {
        backgroundColor: 'nav-hover',
      },
      fontFamily: 'head',
    })};

  text-align: center;
  flex: 1;
  box-shadow: ${props =>
    props.active
      ? `inset 0 -3px 0 0 ${Theme.color('nav-highlight')(props)}`
      : 'inset 0 -1px 0 0 rgba(255, 255, 255, 0.2)'};
  cursor: pointer;
  user-select: none;
`

export function PromotionsDrawer() {
  const translate = Herz.I18n.useTranslate()

  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const { authenticated } = Herz.Auth.useSession()
  const query = getSearchQuery(location)
  const prevQuery = usePrev(query)
  const campaignsQuery = useSafeUpdateQuery({ promotions: 'campaigns' })
  const inventoryQuery = useSafeUpdateQuery({ promotions: 'inventory' })

  React.useEffect(() => {
    if (prevQuery.promotions !== query.promotions) {
      if (query.promotions) {
        // Fetch promotions and inventory on open
        dispatch([Promotions.fetch(), Inventory.fetch()])
        if (query?.promotions === 'campaigns') {
          dispatch(
            Analytics.openPromotionMenu({
              path: location.pathname,
              title: document.title,
            })
          )
        }
      } else {
        // Mark as read when drawer closes
        dispatch(
          Api.markNotificationsAsSeen({
            success: () => [
              Promotions.markAllAsSeen(),
              Inventory.markAllAsSeen(),
            ],
            version: 1,
          })
        )
      }
    }
  }, [prevQuery, query])

  if (!authenticated) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="promotions"
      title={translate('promotion-notifications.title')}
    >
      <Common.Box pb={1} flexGrow="1" display="flex" flexDirection="column">
        <Common.Box
          fontSize={2}
          color="g-text"
          display="flex"
          mx="-16px"
          mb={1}
          flexShrink="0"
        >
          <TabMenuItem
            to={`?${campaignsQuery}`}
            active={query?.promotions === 'campaigns'}
          >
            {translate('promotions.tab.campaigns')}
          </TabMenuItem>
          <TabMenuItem
            to={`?${inventoryQuery}`}
            active={query?.promotions === 'inventory'}
          >
            {translate('promotions.tab.inventory')}
          </TabMenuItem>
        </Common.Box>

        {query?.promotions === 'campaigns' && <PromotionCampaigns />}

        {query?.promotions === 'inventory' && <PromotionInventory />}
      </Common.Box>
    </QueryDrawer>
  )
}
Herz.I18n.Loader.preload(
  [
    'promotion-notifications.title',
    'promotions.tab.campaigns',
    'promotions.tab.inventory',
  ],
  PromotionsDrawer
)
// for @loadable/components
export default PromotionsDrawer
