import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import * as Analytics from './analytics'
import * as Player from './player'
import { Amount } from './amount'
import { Button } from './button'
import { CashMoment } from './cash-moment'
import { LoyaltyProgramRoundProgressBar } from './loyalty-program-round-progress-bar'
import { ProcessesIds } from './constants'
import { QueryDrawer } from './query-drawer'

const MomentWrapper = styled(motion.div)`
  ${css({
    zIndex: 'medium',
  })};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: radial-gradient(#000, transparent);
`

export function LoyaltyDrawer() {
  const [claimed, setClaimed] = React.useState(false)
  const [clickBlocker, setClickBlocker] = React.useState(false)
  const [newPlayerBalance, setNewPlayerBalance] = React.useState(0)
  const translate = Herz.I18n.useTranslate()

  const history = ReactRouter.useHistory()
  const dispatch = ReactRedux.useDispatch()
  const { authenticated } = Herz.Auth.useSession()

  const claimableBalanceCents = ReactRedux.useSelector(state =>
    Player.getClaimableBalanceCents(state.player)
  )
  const disabled = 0 === claimableBalanceCents
  const currentSpin = ReactRedux.useSelector(state =>
    Player.getBetBackProgress(state.player)
  )
  const cycleLength = ReactRedux.useSelector(state =>
    Player.getBetBackDropPoint(state.player)
  )
  const mainBetBalanceCents = ReactRedux.useSelector(state =>
    Player.getMainBetBackBalanceCents(state.player)
  )
  const nextBetBalanceCents = ReactRedux.useSelector(state =>
    Player.getNextBetBackBalanceCents(state.player)
  )
  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [ProcessesIds.CLAIM_BETBACK_REQUEST],
    })
  )

  React.useEffect(() => {
    if (claimed) {
      setClickBlocker(true)

      const id = setTimeout(() => {
        setClickBlocker(false)
      }, 5000)

      return () => {
        clearTimeout(id)
      }
    }
  }, [claimed])

  function handleExit() {
    if (!clickBlocker) {
      setClickBlocker(false)
      setClaimed(false)
    }
  }

  function handleClaim() {
    dispatch([
      Processes.start(ProcessesIds.CLAIM_BETBACK_REQUEST),
      Api.claimBetBack({
        success: res => {
          setNewPlayerBalance(res.value?.result?.totalBalanceCents)
          setClaimed(true)

          return [
            Processes.stop(ProcessesIds.CLAIM_BETBACK_REQUEST),
            Player.updateProgress(res.value.result),
            Analytics.cashOut({
              title: 'LoyaltyDrawer',
              path: location.pathname,
            }),
          ]
        },
        failure: res => [
          Processes.stop(ProcessesIds.CLAIM_BETBACK_REQUEST),
          Notifications.add({
            message: `errors.${res.value.message || 'general.unknown'}`,
            level: 'error',
          }),
        ],
        version: 1,
      }),
    ])
  }

  if (!authenticated) {
    return null
  }

  return (
    <React.Fragment>
      <QueryDrawer
        activeQueryName="loyalty-program"
        title={translate('loyalty-program.title')}
        onSecondaryAction={() => history.push('/faq/loyalty-program')}
        secondaryActionIcon={Icons.Info}
      >
        <React.Fragment>
          <Common.Box
            display="grid"
            justifyContent="center"
            color="g-text"
            fontSize={1}
            textAlign="center"
            backgroundColor="g-bg"
            borderRadius="9999px"
          >
            <Common.Space my={1}>
              <LoyaltyProgramRoundProgressBar height="98px" />
              <Common.Box
                fontSize={2}
                fontWeight="bold"
                fontFamily="head"
                data-testid="loyalty-program.cycle-counter"
              >
                {translate('loyalty-program.cycle-counter', {
                  currentSpin,
                  cycleLength,
                })}
              </Common.Box>
            </Common.Space>
          </Common.Box>
          <Common.Box display="flex" alignItems="center" mt={1}>
            <Common.Box flexGrow="1" fontSize={2}>
              {translate('loyalty-program.cycle-collected')}
            </Common.Box>
            <Common.Box
              display="flex"
              flex="0 1 150px"
              backgroundColor="g-bg"
              py={0}
              justifyContent="center"
              fontSize={3}
              fontWeight="bold"
              borderRadius={1}
              fontFamily="head"
            >
              <Amount
                decimals="show"
                data-testid="loyalty-program.current-cycle-bonus.mainBetBalanceCents"
              >
                {mainBetBalanceCents}
              </Amount>
            </Common.Box>
          </Common.Box>
          <Common.Space py={0} />
          <Common.Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Common.Box flexGrow="1" fontSize={2}>
              {translate('loyalty-program.next-cycle-bonus')}
            </Common.Box>
            <Common.Box
              display="flex"
              flex="0 1 150px"
              justifyContent="center"
              backgroundColor="g-bg"
              py={0}
              fontSize={3}
              fontWeight="bold"
              borderRadius={1}
              color="rgba(255, 255, 255, 0.5)"
              fontFamily="head"
            >
              <Amount
                decimals="show"
                data-testid="loyalty-program.next-cycle-value"
              >
                {nextBetBalanceCents}
              </Amount>
            </Common.Box>
          </Common.Box>
          {!disabled && (
            <Common.Box
              display="grid"
              gridGap={2}
              fontSize={4}
              fontFamily="head"
              textAlign="center"
              mt={2}
              py={2}
              px={1}
              borderRadius={1}
              backgroundImage="url(/images/ripple-background-blue.svg), linear-gradient(257deg, #482d95 55%, #613dc9 11%)"
              backgroundSize="cover"
              backgroundPosition="center"
            >
              {translate('loyalty-program.claimable')}
              <Common.Box fontSize={7} fontWeight="bold" fontFamily="head">
                <Amount
                  decimals="show"
                  data-testid="loyalty-program.claimable-cents"
                >
                  {claimableBalanceCents}
                </Amount>
              </Common.Box>
              <Button
                variant="primary"
                disabled={disabled || loading}
                onClick={handleClaim}
                data-testid="loyalty-program.claim"
              >
                {translate('loyalty-program.claim')}
              </Button>
            </Common.Box>
          )}
          <Common.Box
            pt={3}
            opacity="0.5"
            color="g-text"
            fontSize={1}
            textAlign="center"
          >
            <ReactRouter.Link
              to="/terms-and-conditions/loyalty-program"
              data-testid="loyalty-program.terms-and-conditions.link"
            >
              {translate('loyalty-program.terms-and-conditions')}
            </ReactRouter.Link>
          </Common.Box>
        </React.Fragment>
      </QueryDrawer>
      <AnimatePresence>
        {claimed && (
          <MomentWrapper
            key="moment"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.35 }}
            onClick={handleExit}
          >
            <CashMoment
              claimedCashback={claimableBalanceCents}
              balance={newPlayerBalance}
              dismissable={!clickBlocker}
            />
          </MomentWrapper>
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'loyalty-program.title',
    'loyalty-program.cycle-counter',
    'loyalty-program.cycle-collected',
    'loyalty-program.next-cycle-bonus',
    'loyalty-program.claimable',
    'loyalty-program.claim',
    'loyalty-program.terms-and-conditions',
  ],
  LoyaltyDrawer
)
