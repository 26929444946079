import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import * as Notifications from '@rushplay/notifications'

import * as CombinedSelectors from '../combined-selectors'
import * as Configuration from '../configuration'
import * as Player from '../player'
import { ProcessesIds, RegistrationStep } from '../constants'
import { ProgressBar } from '../progress-bar'
import { QueryDrawer } from '../query-drawer'
import { RegistrationForm } from '../registration-form'
import { fullWidthToHalfWidth } from '../full-width-to-half-width'
import { useLogin } from '../use-login'
import { useServerConfiguration } from '../server-configuration'

import { dataSchema } from './data-schema'

const dataSchemaForKeysPreloading = dataSchema({
  // Enum values are not used in translations so none are needed
  countryCallingCode: [],
  // Enum values are not used in translations so none are needed
  country: [],
  // The actual value is passed as variable thus can be arbitrary
  maxYear: 2022,
  // Enforce extra rule to retrieve this key
  shouldVerifyPhone: true,
})

const formName = 'register'
const preloadSchemaKeys = Forms.findTranslationKeys(
  formName,
  dataSchemaForKeysPreloading
)

export function RegistrationDrawer() {
  const [step, setStep] = React.useState(RegistrationStep.Credentials)
  const history = ReactRouter.useHistory()
  const session = Herz.Auth.useSession()
  const { handleLogin, handleLoginError } = useLogin()
  const { authenticated } = Herz.Auth.useSession()
  const [emailError, setEmailError] = React.useState(null)
  const [phoneError, setPhoneError] = React.useState(null)
  const [showVisualFeedback, setShowVisualFeedback] = React.useState(false)

  const registrationInProgress = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [ProcessesIds.REGISTRATION],
    })
  )

  const translate = Herz.I18n.useTranslate()
  const fingerprint = Herz.Seon.useFingerprint()
  const dispatch = ReactRedux.useDispatch()
  const affiliateClickId = ReactRedux.useSelector(state =>
    CombinedSelectors.getAffiliateClickId(state)
  )

  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const currency = ReactRedux.useSelector(state =>
    Player.getCurrency(state.player)
  )
  const analytics = ReactRedux.useSelector(state => state.analytics)
  const affiliateSubId = Analytics.getSubId(analytics)
  const btag = Analytics.getBtag(analytics)
  const utmCampaign = Analytics.getUtmCampaign(analytics)
  const utmMedium = Analytics.getUtmMedium(analytics)
  const utmSource = Analytics.getUtmSource(analytics)

  const { countries, locale } = useServerConfiguration()

  const countryCallingCodes = countries.map(country =>
    country.countryCode.toString()
  )

  const countryNames = countries.map(country => country.name)

  const schema = React.useMemo(
    () =>
      dataSchema({
        countryCallingCode: countryCallingCodes,
        country: countryNames,
        maxYear: new Date(Date.now()).getFullYear() - 18,
      }),
    [countryCallingCodes, countryNames]
  )

  function startRegistration() {
    dispatch([
      Processes.start(ProcessesIds.REGISTRATION),
      Analytics.registrationStarted(),
      Notifications.dismissAll(),
    ])
  }

  function handleCredentialsSubmit(data) {
    dispatch([
      Processes.start(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
      Api.validateEmail(data.email, {
        success: res => {
          if (res.value?.errorCode) {
            setShowVisualFeedback(true)
            if (res.value?.message === 'email already exists') {
              setEmailError('errors.registration.email-uniqueness')
            } else {
              dispatch(
                Notifications.add({
                  message: 'errors.registration.email-verification.failed',
                  level: 'error',
                })
              )
            }
            return Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST)
          } else {
            return [
              Processes.start(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
              Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
              Api.validatePhoneNumber(
                `+${data.countryCallingCode}`,
                data.phonenumber,
                {
                  success: () => {
                    setShowVisualFeedback(false)
                    setStep(RegistrationStep.Identity)
                    return Processes.stop(
                      ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST
                    )
                  },
                  failure: res => {
                    setShowVisualFeedback(true)

                    if (res.value?.message === 'phone-not-unique') {
                      setPhoneError(
                        'errors.registration.phone-validation.phone-not-unique'
                      )
                    } else if (res.value?.message === 'phone-invalid') {
                      setPhoneError(
                        'errors.registration.phone-validation.phone-invalid'
                      )
                    } else {
                      dispatch(
                        Notifications.add({
                          message:
                            'errors.registration.phone-verification.failed',
                          level: 'error',
                        })
                      )
                    }
                    return Processes.stop(
                      ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST
                    )
                  },
                  version: 2,
                }
              ),
            ]
          }
        },
        failure: () => {
          setShowVisualFeedback(true)
          return [
            Notifications.add({
              message: 'errors.registration.email-verification.failed',
              level: 'error',
            }),
            Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
          ]
        },
        version: 1,
      }),
    ])
  }

  function prepareData(data) {
    const normalizedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        typeof value === 'string' ? fullWidthToHalfWidth(value) : value,
      ])
    )

    return {
      affiliateClickId,
      affiliateSubId,
      // We don't let them decide if they want marketing stuff or not
      // By registering they approve of us sending (Last checkbox that also approves T&C & Privacy-Policy)
      allowEmail: true,
      allowSms: true,
      birthdate: `${normalizedData.bdayDay}/${normalizedData.bdayMonth}/${normalizedData.bdayYear}`,
      city: normalizedData.city,
      clientType,
      countryCallingCode: `+${normalizedData.countryCallingCode}`,
      countryCode:
        countries?.length === 1
          ? countries[0]?.alpha2
          : countries?.find(country => country.name === normalizedData.country)[
              'alpha2'
            ],
      currency,
      email: normalizedData.email,
      firstName: normalizedData.firstName.trim(),
      generateUsername: true,
      language: locale.language,
      lastName: normalizedData.lastName.trim(),
      phoneNumber: normalizedData.phonenumber,
      netrefererBTag: btag,
      password: normalizedData.password,
      passwordConfirmation: normalizedData.password,
      phoneVerificationCode: normalizedData.phoneVerificationCode,
      street: `${normalizedData.street} ${normalizedData.building}`,
      utmCampaign,
      utmMedium,
      utmSource,
      postalCode: normalizedData.zip,
      seonSession: fingerprint.value,
    }
  }

  function endRegistration() {
    dispatch([
      Processes.stop(ProcessesIds.REGISTRATION),
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])
  }

  function handleRegister(data) {
    const userData = prepareData(data)
    dispatch(
      Processes.start(ProcessesIds.REGISTER_REQUEST),
      Processes.start(ProcessesIds.LOGIN_REQUEST)
    )
    session
      .create(userData)
      .then(response => {
        const token = response.createPlayer?.session?.token
        if (token) {
          history.push('/casino')
          handleLogin(token)
          return
        }
        if (response.error) {
          // Results in a toast message
          handleLoginError(response.error)
          response.error?.errors?.forEach(item => {
            dispatch(
              Notifications.add({
                message: `errors.registration.${item}`,
                level: 'error',
              })
            )
          })
          return
        }
        throw new Error('Response without token and error message')
      })
      .catch(error => handleLoginError(error))
      .finally(() => {
        endRegistration()
      })
  }

  if (fingerprint.fetching || authenticated) {
    return null
  }

  return (
    <Forms.Provider
      name={formName}
      schema={schema}
      onSubmit={(formErrors, data) => {
        if (!registrationInProgress) {
          startRegistration()
        }

        switch (step) {
          case RegistrationStep.Credentials: {
            if (
              Object.keys(formErrors).some(error =>
                [
                  '#/properties/email',
                  '#/properties/password',
                  '#/properties/phonenumber',
                ].includes(error)
              )
            ) {
              setShowVisualFeedback(true)
            } else {
              handleCredentialsSubmit(data)
            }
            break
          }

          case RegistrationStep.Identity: {
            if (
              formErrors.constructor === Object &&
              Object.keys(formErrors).length > 0
            ) {
              setShowVisualFeedback(true)
            } else {
              handleRegister(data)
            }
            break
          }

          default: {
            break
          }
        }
      }}
    >
      <QueryDrawer
        onSecondaryAction={
          step > RegistrationStep.Credentials
            ? () => setStep(RegistrationStep.Credentials)
            : null
        }
        activeQueryName="register"
        title={translate('register.title')}
      >
        <Common.Box pb={6} color="g-text">
          <ProgressBar step={step} steps={2} />
          <RegistrationForm
            countryNames={countryNames}
            countryCallingCodes={countryCallingCodes}
            emailErrorKey={emailError}
            phoneErrorKey={phoneError}
            showVisualFeedback={showVisualFeedback}
            step={step}
            onClearEmailErrorKey={() => setEmailError(null)}
            onClearPhoneErrorKey={() => setPhoneError(null)}
          />
        </Common.Box>
      </QueryDrawer>
    </Forms.Provider>
  )
}

Herz.I18n.Loader.preload(
  ['register.title', ...preloadSchemaKeys],
  RegistrationDrawer
)
