import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import * as Theming from './theming'
import * as Icons from './icons'
import * as Promotions from './promotions'
import { Divider } from './divider'
import { HtmlContent } from './html-content'
import { mapIndexed } from './map-indexed'

export function DepositCampaignOfferPicker() {
  const translate = Herz.I18n.useTranslate()
  const campaignOffers = ReactRedux.useSelector(state =>
    Promotions.getOptedInDepositCampaigns(state.promotions, {
      dateNow: Date.now(),
    })
  )

  const { value: amountCents } = Forms.useField('#/properties/amount', {
    noRegister: true,
  })

  if (R.isEmpty(campaignOffers)) {
    return null
  }

  return (
    <Common.Box display="grid" gridGap={1} pt={2}>
      <Common.Box fontSize={3} textAlign="center" pb={0}>
        {translate('promotions.bonuses-on-deposit')}
      </Common.Box>
      {R.map(promotion => {
        const rewards = R.pathOr(
          [],
          [promotion, 'campaign', 'rewards'],
          campaignOffers
        )

        return (
          <CampaignOffer
            amountCents={amountCents}
            promotionId={promotion}
            rewards={rewards}
            key={R.path([promotion, 'campaign', 'id'], campaignOffers)}
          />
        )
      }, R.keys(campaignOffers))}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  ['promotions.bonuses-on-deposit'],
  DepositCampaignOfferPicker
)

function CampaignOffer(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      'offer-picker.minimum-deposit-amount',
      'offer-picker.entice-greater-deposit',
      'promotions.terms-and-conditions',
      `promotions.${props.promotionId}.img-src`,
      `promotions.${props.promotionId}.title`,
      ...props.rewards.map(reward =>
        Promotions.getRewardTranslationKey(reward)
      ),
      `promotions.${props.promotionId}.terms-conditions`,
    ],
    [props.promotionId, props.rewards]
  )

  const promotionMinMax = Promotions.getPromotionMinMax(props.rewards)

  const disabled = props.amountCents < promotionMinMax.minDepositForAnyReward

  const leftToDepositForFullRewardsCents =
    promotionMinMax.minDepositForFullPackageCents - props.amountCents

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        borderRadius={0}
        p={1}
        color={disabled ? 'text-disabled' : 'g-text'}
      >
        <Common.Flex>
          <Common.Box fontSize={2} flexGrow="1">
            <Common.Box fontWeight="bold" style={{ whiteSpace: 'pre-wrap' }}>
              {translate(
                `promotions.${props.promotionId}.title`,
                promotionMinMax
              )}
            </Common.Box>

            {mapIndexed((reward, index) => {
              const isRewardDisabled = Promotions.isRewardDisabled(
                reward,
                props.amountCents
              )
              return (
                <Common.Box
                  color={
                    isRewardDisabled ? 'text-disabled' : 'offer-picker-info'
                  }
                  fontWeight={!isRewardDisabled && 'bold'}
                  fontSize={2}
                  key={index}
                  display="flex"
                  pt={0}
                >
                  <Common.Box fontSize={2} pr={0}>
                    {isRewardDisabled ? <Icons.Clear /> : <Icons.CheckBold />}
                  </Common.Box>
                  <HtmlContent
                    html={{
                      __html: translate(
                        Promotions.getRewardTranslationKey(reward),
                        Promotions.getRewardTranslationVariables(
                          reward,
                          props.amountCents
                        )
                      ),
                    }}
                  />
                </Common.Box>
              )
            }, props.rewards)}
          </Common.Box>

          <Common.Box flexShrink="0">
            <Common.Box
              width="60px"
              height="60px"
              backgroundImage={`url(${translate(
                `promotions.${props.promotionId}.img-src`
              )})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              borderRadius="9999px"
              ml={0}
            />
          </Common.Box>
        </Common.Flex>

        {translate(`promotions.${props.promotionId}.terms-conditions`) && (
          <React.Fragment>
            <Common.Box pt={1} pb={1} color="g-text">
              <Divider />
            </Common.Box>
            <Common.Text fontSize={1} textDecoration="underline" color="g-text">
              <ReactRouter.Link to={`/promotions/${props.promotionId}`}>
                {translate('promotions.terms-and-conditions')}
              </ReactRouter.Link>
            </Common.Text>
          </React.Fragment>
        )}
        {!disabled && leftToDepositForFullRewardsCents > 0 && (
          <Common.Box
            fontSize={1}
            color="info"
            pt={1}
            fontStyle="italic"
            display="flex"
            justifyContent="center"
            alignItems="center"
            lineHeight="1.25"
          >
            <Common.Box pr="4px" fontSize={2} display="inline-flex">
              <Icons.Info />
            </Common.Box>
            {translate('offer-picker.entice-greater-deposit', {
              depositLeftForFullRewardsCents: leftToDepositForFullRewardsCents,
            })}
          </Common.Box>
        )}
        {disabled && (
          <Common.Box
            lineHeight="1.25"
            fontSize={1}
            color="danger"
            pt={1}
            fontStyle="italic"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box pr="4px" fontSize={2} display="inline-flex">
              <Icons.Error />
            </Common.Box>
            {translate('offer-picker.minimum-deposit-amount', {
              minDepositAmount: promotionMinMax.minDepositForAnyReward,
            })}
          </Common.Box>
        )}
      </Common.Box>
    </Theming.Alternative>
  )
}

CampaignOffer.propTypes = {
  amountCents: PropTypes.number,
  promotionId: PropTypes.string,
  rewards: PropTypes.array,
}
