import * as R from 'ramda'
import * as polished from 'polished'
import { fontSize } from 'styled-system'

import styled from '@emotion/styled'

function color(props) {
  const color = R.path(['colors', props.color], props.theme)

  const boxShadowColor = polished.rgba(polished.shade(0.65, color), 0.25)
  const boxShadow = `0 0.15em 0.3em -0.05em ${boxShadowColor}`

  if (props.filled) {
    return {
      backgroundColor: color,
      borderColor: color,
      boxShadow,
      color: '#ffffff',
    }
  }

  if (props.outlined) {
    return {
      backgroundColor: 'transparent',
      borderColor: color,
      boxShadow,
      color,
    }
  }

  return {
    backgroundColor: '#ffffff',
    borderColor: 'currentColor',
    boxShadow,
    color,
  }
}

export const RoundButton = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2em',
    height: '2em',
    borderRadius: '9999px',
    borderStyle: 'solid',
    borderWidth: '2px',
  },
  color,
  fontSize
)

RoundButton.displayName = 'RoundButton'
