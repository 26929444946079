import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { QuickNavigation } from './quick-navigation'
import { useGameInfoUrlQuery } from './use-game-info-url-query'

const Wrapper = styled.div`
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  pointer-events: none;
  ${css({
    display: ['flex', 'none'],
  })}
`

export function QuickNavigationMenu(props) {
  const location = ReactRouter.useLocation()
  const gameInfoQuery = useGameInfoUrlQuery()
  const { authenticated } = Herz.Auth.useSession()

  if (!authenticated) {
    return null
  }

  // Hides menu when launching game
  if (
    (gameInfoQuery.referrer === 'live-casino' && authenticated) ||
    gameInfoQuery.referrer === 'casino' ||
    gameInfoQuery.referrer === 'dashboard' ||
    location.pathname.includes('/sports') ||
    location.pathname.includes('/horse-racing')
  ) {
    return null
  }

  // Disables casino navigation when in the casino lobby,
  // but not when in game info view.
  const disabledCasinoNavigation =
    location.pathname.includes('/casino') &&
    !location.pathname.includes('/games')

  return (
    <Wrapper>
      <QuickNavigation
        exact
        align="left"
        icon={Icons.Home}
        location="/"
        onClick={props.onClick}
      />
      <QuickNavigation
        disabled={disabledCasinoNavigation}
        align="right"
        icon={Icons.ViewModule}
        location="/casino"
        onClick={props.onClick}
      />
    </Wrapper>
  )
}

QuickNavigationMenu.propTypes = {
  onClick: PropTypes.func,
}
