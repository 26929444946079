import React from 'react'

import * as Herz from '@rushplay/herz'

import { Toast } from './toast'

export function WithdrawalFeeInfo() {
  const translate = Herz.I18n.useTranslate()

  return (
    <Toast title={translate('toast.info')} variant="info">
      {translate('wallet.withdraw.fee-info')}
    </Toast>
  )
}

Herz.I18n.Loader.preload(
  ['wallet.withdraw.fee-info', 'toast.info'],
  WithdrawalFeeInfo
)

// for @loadable/components
export default WithdrawalFeeInfo
