import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Icons from './icons'

export function EmptyPromotionInventoryCard(props) {
  const translate = Herz.I18n.useTranslate(() => [props.translationKey], [
    props.translationKey,
  ])
  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      lineHeight="1.35"
      fontSize={1}
      backgroundColor="empty-card-background"
      py={3}
      px={1}
      borderRadius={1}
      flexGrow="1"
      flexShrink="0"
    >
      <Common.Box pb={1} fontSize={6}>
        <Icons.Info />
      </Common.Box>
      {translate(props.translationKey)}
    </Common.Box>
  )
}

EmptyPromotionInventoryCard.propTypes = {
  translationKey: PropTypes.string,
}

// for @loadable/components
export default EmptyPromotionInventoryCard
