import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import * as Common from '@rushplay/common'
import * as Triggers from '@rushplay/triggers'
import * as Herz from '@rushplay/herz'
import { Global, css } from '@emotion/core'

import * as Configuragtion from './configuration'
import * as Player from './player'
import { useMenuQueries } from './use-menu-queries'
import { useServerConfiguration } from './server-configuration'

function useClient() {
  return React.useContext(Urql.Context)
}

const getBetbyToken = `
  query getBetbyToken {
    player {
      betbyToken
    }
  }
`

function setOffsetTop(token) {
  if (window.innerWidth > 1000) {
    return 0
  } else {
    if (!token || window.innerWidth < 800) {
      return 46
    }
    return 56
  }
}

const getSportsTrigger = Triggers.createGetTriggerSelector()

export function SportsbookDirect(props) {
  const { depositQuery, loginQuery, registerQuery } = useMenuQueries()
  const [response] = Urql.useQuery({ query: getBetbyToken })
  const ref = React.useRef(null)
  const [shouldInitApp, setShouldInitApp] = React.useState(false)
  const [btRendererInited, setBtRendererInited] = React.useState(false)
  const [scrollbarWidth, setScrollbarWidth] = React.useState('0px')
  const history = ReactRouterDom.useHistory()

  const dispatch = ReactRedux.useDispatch()
  const sportsTrigger = ReactRedux.useSelector(state =>
    getSportsTrigger(state.triggers, {
      kind: 'sports',
      event: 'onboarding',
    })
  )
  const hasSeenSports = ReactRedux.useSelector(state =>
    Player.hasSeenSports(state.player)
  )
  const { authenticated } = Herz.Auth.useSession()

  const UrqlClient = useClient()
  const token = response.data ? response.data.player.betbyToken : null
  const { locale } = useServerConfiguration()
  const scriptUrl = ReactRedux.useSelector(state =>
    Configuragtion.getBetbyScriptUrl(state.configuration)
  )
  const brandId = ReactRedux.useSelector(state =>
    Configuragtion.getBetbyBrandId(state.configuration)
  )

  const sportsUrl = '/sports'

  // Makes sure client is available on window (script loaded properly)
  React.useEffect(() => {
    if (!btRendererInited) {
      const timerId = window.setInterval(() => {
        setBtRendererInited(typeof window.BTRenderer !== 'undefined')
      }, 250)
      return () => {
        clearInterval(timerId)
      }
    }
  }, [btRendererInited])

  const client = React.useMemo(() => {
    if (process.browser && btRendererInited) {
      return new window.BTRenderer()
    }
  }, [process.browser, btRendererInited])

  const widgetConfig = React.useMemo(
    () =>
      props.widgetName
        ? {
            widgetName: props.widgetName,
            widgetParams: {
              minFrameHeight: props.minFrameHeight,
              placeholder: props.widgetPlaceholder,
              onBannerClick: ({ url }) =>
                history.push(`${sportsUrl}?bt-path=${url}`),
            },
          }
        : {},
    [props.widgetName, props.minFrameHeight, props.widgetPlaceholder]
  )

  // Initiates client
  React.useEffect(() => {
    if (shouldInitApp) {
      setShouldInitApp(false)
      const offsetTop = setOffsetTop(token)

      client.initialize({
        brand_id: brandId,
        token,
        // TODO: add proper values when we integrate betby sportsbook for ME
        themeName: 'casino-me',
        lang: locale.language,
        target: document.querySelector('#betby'),
        betSlipOffsetBottom: 0,
        betSlipOffsetTop: offsetTop,
        stickyTop: offsetTop,
        betslipZIndex: 100,
        cssUrls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],
        ...widgetConfig,
        onSessionRefresh: () => window.location.reload(),
        goToClick: ({ url }) => history.push(`${sportsUrl}?bt-path=${url}`),
        onBannerClick: ({ url }) => history.push(`${sportsUrl}?bt-path=${url}`),
        onLogin: () => history.push(`?${loginQuery}`),
        onRecharge: () => history.push(`?${depositQuery}`),
        onRegister: () => history.push(`?${registerQuery}`),
        onSportClick: () => history.push(sportsUrl),
        onTokenExpired: () =>
          UrqlClient.query(getBetbyToken)
            .toPromise()
            .then(res => res.data.player.betbyToken),
      })
    }
  }, [locale, token, shouldInitApp, client])

  React.useEffect(() => {
    const queries = {
      sm: '(max-width: 799px)',
      md: '(min-width: 800px) and (max-width: 999px)',
      lg: '(min-width: 1000px)',
    }
    const mediaQueryLists = {}
    const keys = R.keys(queries)

    function handleChange(isMatching) {
      const match = R.filter(query => query, isMatching)

      switch (R.head(R.keys(match))) {
        case 'sm': {
          client.updateOptions({ stickyTop: 46, betSlipOffsetTop: 46 })
          break
        }
        case 'md': {
          if (token) {
            client.updateOptions({ stickyTop: 56, betSlipOffsetTop: 56 })
          } else {
            client.updateOptions({ stickyTop: 46, betSlipOffsetTop: 46 })
          }
          break
        }
        case 'lg': {
          client.updateOptions({ stickyTop: 0, betSlipOffsetTop: 0 })
          break
        }
      }
    }
    function handleQueryListener() {
      const isMatching = R.reduce(
        (acc, media) => {
          acc[media] = Boolean(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          )
          return acc
        },
        {},
        R.keys(queries)
      )

      handleChange(isMatching)
    }

    R.forEach(media => {
      mediaQueryLists[media] = window.matchMedia(queries[media])
    }, keys)

    R.forEach(media => {
      mediaQueryLists[media].addListener(handleQueryListener)
    }, keys)

    return () => {
      R.forEach(media => {
        mediaQueryLists[media].removeListener(handleQueryListener)
      }, keys)
    }
  }, [client])

  React.useEffect(() => {
    if (process.browser) {
      setScrollbarWidth(window.innerWidth - document.body.clientWidth + 'px')
    }
  }, [process.browser])

  // Launch client
  React.useEffect(() => {
    if (ref.current && brandId && btRendererInited && !response.fetching) {
      setShouldInitApp(true)
    }
  }, [ref.current, brandId, btRendererInited, response.fetching])

  React.useEffect(() => {
    const markSportsAsSeen = authenticated && token && !hasSeenSports
    if (markSportsAsSeen) {
      dispatch([
        Player.updateSportsSeen(true),
        sportsTrigger ? Triggers.removeSelected(sportsTrigger.id) : null,
      ])
    }
  }, [authenticated, token, hasSeenSports, sportsTrigger, dispatch])

  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      if (client) {
        client.kill()
      }
    }
  }, [client])

  return (
    <React.Fragment>
      <Global
        styles={css`
          body {
            overflow-x: visible;
          }
        `}
      />
      <Helmet>
        <script src={scriptUrl} id="betby-script" />
      </Helmet>
      <Common.Box
        id="betby"
        ref={ref}
        width={
          props.widgetName
            ? '100%'
            : ['100vw', null, `calc(100vw - 200px - ${scrollbarWidth})`]
        }
      />
    </React.Fragment>
  )
}

SportsbookDirect.propTypes = {
  widgetName: PropTypes.string,
  widgetPlaceholder: PropTypes.string,
  minFrameHeight: PropTypes.number,
}
