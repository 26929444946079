import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import querystring from 'querystring'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'

import * as pickedGames from '../picked-games'

import { Dashboard as Component } from './dashboard'

export const Dashboard = R.compose(
  ReactRouter.withRouter,
  connect(
    (state, props) => ({
      gameIndex: pickedGames.getSelectedGameIndex(state.pickedGames),
      games: pickedGames.getPickedGames(state.pickedGames),
      gamesIds: pickedGames.getGamesIds(state.pickedGames),
      isAllGamesUnset: pickedGames.isAllGamesUnset(state.pickedGames),
      loading: processes.isRunning(state.processes, {
        ids: [pickedGames.UPDATE_PICKED_GAMES_PROCESS],
      }),
      view: R.path(
        ['view'],
        querystring.parse(R.drop(1, props.location.search))
      ),
    }),
    (dispatch, props) => ({
      onCustomizeGame: gameIndex =>
        dispatch(pickedGames.setSelectedGameIndex(gameIndex)),
      onFetch: () =>
        dispatch(
          api.fetchPlayerCustomGames({
            success: res => pickedGames.updatePickedGames(res.value.games),
            failure: () =>
              pickedGames.updatePickedGames(
                new Error('picked-games.fetch-failed')
              ),
            version: 1,
          })
        ),
      onUpdateView: view => {
        props.history.push(`?view=${view}`)
      },
      onUpdatePickedGames: games =>
        dispatch([
          processes.start(pickedGames.UPDATE_PICKED_GAMES_PROCESS),
          api.updatePlayerCustomGames(games, {
            success: res => [
              processes.stop(pickedGames.UPDATE_PICKED_GAMES_PROCESS),
              pickedGames.setSelectedGameIndex(null),
              pickedGames.updatePickedGames(res.value.games),
            ],
            failure: () => [
              pickedGames.setSelectedGameIndex(null),
              pickedGames.updatePickedGames(
                new Error('picked-games.update-failed')
              ),
            ],
            version: 1,
          }),
        ]),
    })
  )
)(Component)

// For '@loadable/components'
export default Dashboard
