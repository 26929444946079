import * as React from 'react'
import PropTypes from 'prop-types'
import { variant } from 'styled-system'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

const Input = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

const Label = styled.label`
  ${props => (props.disabled ? `cursor: not-allowed;` : `cursor: pointer;`)};
`

const Style = styled('div', {
  shouldForwardProp: Common.noneOf(['variant']),
})`
  // Workaround: theme 'variants' are not read otherwise
  ${variant({
    scale: 'radio-buttons',
    variants: { _: {} },
  })};
`

const Text = styled.div`
  line-height: 1.2;
  text-align: center;
  display: inline;
`

export function RadioButton(props) {
  return (
    <Style
      variant={props.variant}
      className={props.layout}
      checked={props.checked}
    >
      <Input
        id={props.label}
        name={props.name}
        type="radio"
        disabled={props.disabled}
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        tabIndex="0"
      />
      <Label disabled={props.disabled} htmlFor={props.label}>
        <Text>{props.label}</Text>
      </Label>
    </Style>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  layout: PropTypes.oneOf(['col-half', 'col-third']),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['radio', 'button']),
  onChange: PropTypes.func,
}
