import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Promotions from '../promotions'
import * as Theming from '../theming'
import { Button } from '../button'
import { HtmlContent } from '../html-content'

const endTextColor = {
  day: 'g-text',
  hour: 'textDanger',
  minutes: 'red',
}

export function PromotionCard(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      'promotions.claimed',
      `promotions.${props.id}.button-link`,
      'promotions-label-active',
      'promotions-label-claimed',
      `promotions.${props.id}.img-src`,
      `promotions.time-left.${props.timeLeft.id}.end`,
      `promotions.time-left.${props.timeLeft.id}.start`,
      `promotions.${props.id}.title`,
      `promotions.${props.id}.description`,
      `promotions.${props.id}.terms-conditions`,
      'promotions.terms-and-conditions',
      'promotions.cancel',
    ],
    [props.id, props.timeLeft.id]
  )

  const rewards = props.campaign.rewards ?? []
  const isOptedIn = props.campaign.optInState === 'in'
  const isAutomaticallyOptedIn = props.campaign.optInState === 'automatic'
  const isPromotionOptedIn = isOptedIn || isAutomaticallyOptedIn

  const isLabelDisplayed = [
    isOptedIn,
    isAutomaticallyOptedIn,
    props.campaign.claimed,
  ].includes(true)

  const isCancelButton = isOptedIn && !props.campaign.claimed

  const renderActionButton = props.campaign.claimed
    ? translate('promotions.claimed')
    : translate(`promotions.${props.id}.button-link`)

  return (
    <Theming.Alternative>
      <Common.Box
        position="relative"
        backgroundColor="g-bg"
        color="g-text"
        borderRadius={0}
        display="flex"
        alignItems="center"
        p={1}
        borderLeft="10px solid"
        borderColor={props.seen ? 'disabled' : 'unseen-promotion-border'}
      >
        {isLabelDisplayed && (
          <Common.Box
            position="absolute"
            top="0px"
            left="-10px"
            width="65px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="3px"
            backgroundColor={
              isPromotionOptedIn && !props.campaign.claimed
                ? 'active-promotion-badge'
                : 'claimed-promotion-badge'
            }
            borderRadius={0}
            fontSize={1}
            fontWeight="bold"
            color={
              isPromotionOptedIn && !props.campaign.claimed
                ? 'card-text'
                : 'claimed-promotion-badge-text'
            }
            zIndex="high"
          >
            {isPromotionOptedIn && !props.campaign.claimed
              ? translate('promotions-label-active')
              : translate('promotions-label-claimed')}
          </Common.Box>
        )}
        <Common.Box
          flexShrink="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Common.Box
            borderRadius="9999px"
            backgroundImage={`url(${translate(
              `promotions.${props.id}.img-src`
            )})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundColor="disabled"
            height="60px"
            width="60px"
            flexShrink="0"
            style={{ filter: props.upcoming ? 'grayscale(1)' : 'grayscale(0)' }}
          />
          {props.activeTo && !props.upcoming && (
            <Common.Box
              fontSize={0}
              pt={0}
              color={endTextColor[props.timeLeft.id]}
              textAlign="center"
              maxWidth="60px"
            >
              {translate(`promotions.time-left.${props.timeLeft.id}.end`, {
                timeLeft: props.timeLeft.end,
              })}
            </Common.Box>
          )}
          {props.upcoming && (
            <Common.Box
              fontSize={0}
              pt={0}
              color="g-text"
              textAlign="center"
              maxWidth="60px"
            >
              {translate(`promotions.time-left.${props.timeLeft.id}.start`, {
                timeLeft: props.timeLeft.start,
              })}
            </Common.Box>
          )}
        </Common.Box>
        <Common.Box
          pl={1}
          display="inline-flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Common.Box
            pb="4px"
            fontFamily="head"
            fontSize={2}
            fontWeight="bold"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {translate(`promotions.${props.id}.title`, {
              ...Promotions.getPromotionMinMax(rewards),
              ...Promotions.getPromotionTranslationVariables(rewards),
            })}
          </Common.Box>
          <Common.Text fontSize={1}>
            <HtmlContent
              html={{
                __html: translate(
                  `promotions.${props.id}.description`,
                  Promotions.getPromotionTranslationVariables(rewards)
                ),
              }}
            />
          </Common.Text>
          {translate(`promotions.${props.id}.terms-conditions`) && (
            <Common.Box
              fontSize={1}
              color="g-text"
              pt={0}
              style={{ textDecoration: 'underline' }}
            >
              <ReactRouter.Link to={`/promotions/${props.id}`}>
                {translate('promotions.terms-and-conditions')}
              </ReactRouter.Link>
            </Common.Box>
          )}
          {props.ctaLink && !props.upcoming && (
            <Common.Box flexGrow="1" pt={1}>
              <Button
                stretch
                disabled={
                  isOptedIn && !props.campaign.claimed
                    ? false
                    : props.campaign.claimed
                }
                variant="primary"
                fontSize={1}
                onClick={props.onClaim}
              >
                {isCancelButton
                  ? translate('promotions.cancel')
                  : renderActionButton}
              </Button>
            </Common.Box>
          )}
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

PromotionCard.defaultProps = {
  timeLeft: {},
  campaign: {},
}

PromotionCard.propTypes = {
  activeTo: PropTypes.string,
  activeFrom: PropTypes.string,
  campaign: PropTypes.object,
  ctaLink: PropTypes.string,
  timeLeft: PropTypes.object,
  id: PropTypes.string.isRequired,
  seen: PropTypes.bool,
  upcoming: PropTypes.bool,
  onClaim: PropTypes.func.isRequired,
}
