import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Icons from './icons'
import { Amount } from './amount'
import { mapIndexed } from './map-indexed'
import { parseStatistic } from './parse-statistic'
import { useServerConfiguration } from './server-configuration'

export function GameStatistics(props) {
  const { locale } = useServerConfiguration()

  const rawStatistics = R.pick(
    [
      'jackpotValue',
      'feature',
      'rtp',
      'volatility',
      'biggestWinCents',
      'minBetCents',
      'maxBetCents',
      'dealerName',
      'seatsTotal',
      'seatsAvailable',
      'betBehind',
      'releaseDate',
    ],
    R.filter(Boolean, props)
  )

  const statistics = R.reduce(
    (statistics, statisticKey) => {
      const statistic = parseStatistic(statisticKey, props, props.catalogType)

      if (R.not(R.isNil(statistic))) {
        return R.append(statistic, statistics)
      }

      return statistics
    },
    [],
    R.keys(rawStatistics)
  )

  const translate = Herz.I18n.useTranslate(
    () => [
      ...statistics.flatMap(statistic => [
        `game-info.${statistic.label}`,
        statistic.key,
      ]),
    ],
    [statistics]
  )

  return (
    <Common.Box
      display="grid"
      gridTemplateColumns={[
        'repeat(2, minmax(max-content, 1fr))',
        `repeat(${statistics.length > 2 ? 3 : 2}, minmax(max-content, 1fr))`,
      ]}
      gridGap={1}
    >
      <AnimatePresence>
        {mapIndexed(
          (statistic, index) => (
            <motion.div
              key={`${props.gameId}-${statistic.label}`}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              transition={{
                type: 'spring',
                duration: 0.25,
                delay: index * 0.05,
                damping: 12,
              }}
            >
              <Common.Box
                fontSize={[1, 2]}
                fontWeight="bold"
                textAlign="center"
                pb={0}
                opacity="0.5"
                fontFamily="head"
                data-testid={`game-info.statistics.${statistic.label}`}
              >
                {translate(`game-info.${statistic.label}`)}
              </Common.Box>
              <Common.Box
                color="g-text"
                fontSize={2}
                fontWeight="bold"
                textAlign="center"
                bg="black"
                borderRadius={0}
                padding={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderWidth="1px"
                borderColor="rgba(255, 255, 255, 0.3)"
                borderStyle="solid"
                fontFamily="head"
              >
                {statistic.format === 'jackpot' && (
                  <Amount
                    currency={statistic.currency}
                    decimals="show"
                    data-testid="game-info.statistics.jackpot.value"
                  >
                    {statistic.value}
                  </Amount>
                )}
                {statistic.format === 'currency' && (
                  <Amount
                    decimals="show"
                    data-testid="game-info.statistics.currency.value"
                  >
                    {statistic.value}
                  </Amount>
                )}
                {statistic.format === 'live-casino-currency' && (
                  <React.Fragment>
                    <Common.Space pr={0}>
                      <Icons.Roulette />
                    </Common.Space>
                    {statistic.value}
                  </React.Fragment>
                )}
                {statistic.format === 'translate' &&
                  (translate(statistic.key)
                    ? translate(statistic.key)
                    : statistic.value)}
                {statistic.format === 'date' &&
                  new Date(statistic.value).toLocaleDateString(locale.slug, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                {!statistic.format && statistic.value}
              </Common.Box>
            </motion.div>
          ),
          statistics
        )}
      </AnimatePresence>
    </Common.Box>
  )
}

GameStatistics.propTypes = {
  gameId: PropTypes.string,
  catalogType: PropTypes.oneOf(['live-casino', 'casino']),
  jackpotValue: PropTypes.number,
  feature: PropTypes.string,
  rtp: PropTypes.string,
  volatility: PropTypes.string,
  biggestWinCents: PropTypes.number,
  minBetCents: PropTypes.number,
  maxBetCents: PropTypes.number,
  dealerName: PropTypes.string,
  seatsTotal: PropTypes.number,
  seatsAvailable: PropTypes.number,
  betBehind: PropTypes.bool,
  releaseDate: PropTypes.string,
}
