import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import { Button } from './button'

export function WelcomeOfferModeSelector(props) {
  const translate = Herz.I18n.useTranslate()
  return (
    <Common.Box minWidth="50%">
      <ReactRouter.Link to={`/casino/games/${props.gameId}?mode=classic`}>
        <Button stretch variant="primary">
          {translate('game-info.play-for-fun')}
        </Button>
      </ReactRouter.Link>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(['game-info.play-for-fun'], WelcomeOfferModeSelector)

WelcomeOfferModeSelector.propTypes = {
  gameId: PropTypes.string,
  location: PropTypes.object,
}
