import * as React from 'react'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import FeaturedGame from './featured-game'

const LoginBox = loadable(() => import('./login-box'))

export function LoginPage() {
  const translate = Herz.I18n.useTranslate()
  return (
    <FeaturedGame>
      <Common.Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent={['flex-start', 'center']}
        alignItems="center"
        width="100%"
        flexGrow="1"
        flexShrink="1"
        maxWidth="330px"
        pb="200px"
      >
        <Common.Box
          as="h1"
          fontSize={7}
          fontWeight="bold"
          py={1}
          textAlign="center"
          fontFamily="head"
        >
          {translate('login-page.welcome-back')}
        </Common.Box>
        <LoginBox />
      </Common.Box>
    </FeaturedGame>
  )
}
Herz.I18n.Loader.preload(['login-page.welcome-back'], LoginPage)

// For @loadable/components
export default LoginPage
