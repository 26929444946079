import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import { Dashboard } from './dashboard'
import { LandingPage } from './landing-page'
import { Spinner } from './spinner'

export function Home(props) {
  const { authenticated, fetching } = Herz.Auth.useSession()

  if (fetching) {
    return (
      <Common.Box width="100%">
        <Spinner />
      </Common.Box>
    )
  }

  if (authenticated) {
    return <Dashboard {...props} />
  }

  return <LandingPage {...props} />
}

// For @loadable/components
export default Home
