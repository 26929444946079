import * as React from 'react'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

const items = [1, 2, 3, 4]

export function InformativeSection() {
  const translate = Herz.I18n.useTranslate()

  return (
    <Common.Box
      display="flex"
      flexWrap="wrap"
      width="100%"
      justifyContent="space-around"
    >
      {items.map(item => (
        <Common.Box
          key={item}
          display="flex"
          flexDirection="column"
          color="g-text"
          alignItems="center"
          flexBasis="0"
          flexGrow="1"
          flexShrink="1"
          maxWidth="280px"
          minWidth="200px"
          m={2}
          textAlign="center"
          lineHeight="1.25"
        >
          <LazyLoad once offset={100} height="75px">
            <img
              src={translate(`informative.no${item}.icon`)}
              alt={item}
              height="75"
            />
          </LazyLoad>
          <Common.Box
            fontWeight="bold"
            py={1}
            fontSize={4}
            lineHeight="1.5"
            as="h3"
            fontFamily="head"
          >
            {translate(`informative.no${item}.title`)}
          </Common.Box>
          <Common.Box
            opacity="0.8"
            fontSize={2}
            as="p"
            lineHeight="1.8"
            textAlign="left"
          >
            {translate(`informative.no${item}.description`)}
          </Common.Box>
        </Common.Box>
      ))}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    ...items.flatMap(item => [
      `informative.no${item}.icon`,
      `informative.no${item}.title`,
      `informative.no${item}.description`,
    ]),
  ],
  InformativeSection
)
