import * as EmotionTheming from 'emotion-theming'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as urql from 'urql'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import URL from 'url'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Icons from '../icons'
import { Amount } from '../amount'
import { Button } from '../button'
import { GameTileThumbnail } from '../game-tile-thumbnail'
import { HtmlContent } from '../html-content'
import { InformativeSection } from '../informative-section'
import { LiveCasinoGameDisplay } from '../live-casino-game-display'
import { PaymentProviderImages } from '../payment-provider-images'
import { RegistrationExplanation } from '../registration-explanation'
import { WelcomeOfferExplanation } from '../welcome-offer-explanation'
import { WelcomeOfferGameInfo } from '../welcome-offer-game-info'
import { mapIndexed } from '../map-indexed'
import { useImgproxyUrl } from '../use-imgproxy-url'

const getFeaturedLandingGames = `
query FeaturedLandingGames {
  catalog(key: "featured-landing-game") {
    ... on  GetCatalogSuccess{
        kind
        categories {
          id
          sections {
            id
            key
            layout
            tileSize
            totalGamesCount
            games {
              backgroundUrl
              backgroundOverlayUrl
              category
              colorMode
              displayProvider
              freePlayExcluded
              id
              imageUrl
              key
              preRelease
              provider
              tags
              title
              titleUrl
              jackpotValue
              feature
              rtp
              volatility
              biggestWinCents
              minBetCents
              maxBetCents
              dealerName
              seatsTotal
              seatsAvailable
              betBehind
              releaseDate
            }
          }
        }
      }
  }
}
`

export function LandingPage() {
  const translate = Herz.I18n.useTranslate()
  const theme = EmotionTheming.useTheme()
  const dispatch = ReactRedux.useDispatch()

  const [response] = urql.useQuery({
    query: getFeaturedLandingGames,
  })

  const [bigWins, setBigWins] = React.useState([])

  React.useEffect(() => {
    dispatch(
      Api.fetchLiveStatistics(
        { limit: 3 },
        {
          success: res => setBigWins(R.path(['biggestSettlement'], res.value)),
          version: 3,
        }
      )
    )
  }, [Api.fetchLiveStatistics])

  const welcomeOfferBackground = useImgproxyUrl(
    URL.parse(translate('landing-page.welcome-offer-background')).pathname,
    { resizingType: 'fit', resizingWidth: '600' }
  )

  const welcomeOfferImage = useImgproxyUrl(
    URL.parse(translate('landing-page.welcome-offer-image')).pathname,
    {
      resizingType: 'fit',
      resizingWidth: '600',
      dpr: 1,
    }
  )

  const welcomeOfferImageMobile = useImgproxyUrl(
    URL.parse(translate('landing-page.welcome-offer-image-mobile')).pathname,
    {
      resizingType: 'fit',
      resizingWidth: '600',
      dpr: 1,
    }
  )

  if (response.fetching) {
    return (
      <Common.Box
        display="flex"
        justifyContent="center"
        alignSelf="center"
        fontSize={['48px', '64px']}
        pt={3}
        width="100%"
      >
        <Icons.Spinner />
      </Common.Box>
    )
  }

  const game = R.path(
    ['data', 'catalog', 'categories', 0, 'sections', 0, 'games', 0],
    response
  )

  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ whiteSpace: 'pre-wrap' }}
    >
      <Common.Box
        backgroundImage={[
          `linear-gradient(to bottom, transparent 90%, rgba(23, 6, 29, 0)), url(${theme['bg-pattern-stripe-small']}), url(${welcomeOfferBackground})`,
        ]}
        backgroundSize="cover"
        backgroundPosition="bottom, center"
        backgroundRepeat="no-repeat"
      >
        <Common.Box
          py={1}
          px={1}
          maxWidth={['100%', null, '1440px']}
          mx="auto"
          backgroundImage={[
            `url(${welcomeOfferImageMobile})`,
            `url(${welcomeOfferImage})`,
          ]}
          backgroundSize={['contain', '400px']}
          backgroundPosition={['top', 'top 20px right']}
          backgroundRepeat="no-repeat"
        >
          <Common.Box
            display="flex"
            flexDirection="column"
            alignItems={['center', 'flex-start']}
          >
            <Common.Box
              order={[3, 1]}
              pt={[3, '120px']}
              fontSize={[2, 6]}
              fontWeight="800"
              lineHeight="1.5"
              fontFamily="head"
            >
              {translate('landing-page.header-info')}
            </Common.Box>
            <Common.Box
              order={[4, 2]}
              fontSize={[5, 8]}
              fontWeight="800"
              lineHeight="1.5"
              fontFamily="head"
            >
              {translate('landing-page.header-welcome-offer-info')}
            </Common.Box>
            <Common.Box
              order={2}
              display={['block', 'none']}
              color="primary"
              fontSize={3}
              fontWeight="800"
            >
              <ReactRouter.Link to="?login=me">
                {translate('landing-page.login-link')}
              </ReactRouter.Link>
            </Common.Box>
            <Common.Box
              order={[1, 3]}
              width={['300px', '330px']}
              pt={['300px', 3]}
              pb={[3, '120px']}
            >
              <ReactRouter.Link to="?register=me">
                <Button stretch variant="primary" fontSize={[5, 6]}>
                  {translate('landing-page.register')}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
          </Common.Box>
          <WelcomeOfferExplanation />
        </Common.Box>
      </Common.Box>
      <PaymentProviderImages />
      <Common.Box maxWidth={['100%', null, '1440px']} mx="auto">
        <Common.Box pt={[3, '50px']} minHeight="480px" maxWidth="100%">
          {game && (
            <WelcomeOfferGameInfo
              gameId={game.id}
              backgroundUrl={game.backgroundUrl}
              gameKey={game.key}
              provider={game.displayProvider || game.provider}
              title={game.title}
              titleUrl={game.titleUrl}
              jackpotValue={game.jackpotValue}
              feature={game.feature}
              rtp={game.rtp}
              volatility={game.volatility}
              biggestWinCents={game.biggestWinCents}
              minBetCents={game.minBetCents}
              maxBetCents={game.maxBetCents}
              dealerName={game.dealerName}
              seatsTotal={game.seatsTotal}
              seatsAvailable={game.seatsAvailable}
              betBehind={game.betBehind}
              releaseDate={game.releaseDate}
            />
          )}
        </Common.Box>

        <Common.Box maxWidth="100vw" py={[2, 4]}>
          <RegistrationExplanation />
        </Common.Box>

        <Common.Box py={[2, 4]}>
          <LiveCasinoGameDisplay />
        </Common.Box>

        <Common.Box px={[1, 2]}>
          <Common.Box
            fontWeight="800"
            fontSize={[4, 6]}
            pb={[1, 3]}
            lineHeight="1.5"
            fontFamily="head"
          >
            {translate('big-winners.header')}
          </Common.Box>
          <Common.Box
            display="grid"
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              null,
              null,
              'repeat(3, 1fr)',
            ]}
            gridGap={1}
          >
            {mapIndexed(
              (win, i) => (
                <Common.Box
                  key={i}
                  backgroundColor="big-win-background"
                  borderRadius="8px"
                  p={1}
                  display="flex"
                  alignItems="center"
                  style={{ wordBreak: 'break-word' }}
                >
                  <GameTileThumbnail
                    backgroundUrl={win.backgroundUrl}
                    backgroundOverlayUrl={win.backgroundOverlayUrl}
                    backgroundOverlayImageAlignment={
                      win.backgroundOverlayImageAlignment
                    }
                    gameName={win.gameName}
                    titleUrl={win.titleUrl}
                  />
                  <Common.Box pl={1}>
                    <Common.Box
                      fontSize={5}
                      fontWeight="800"
                      textShadow="0px 0px 6px #CF59FF"
                      fontFamily="head"
                    >
                      <Amount currency={win.currency} decimals="hide">
                        {win.amountCents}
                      </Amount>
                    </Common.Box>
                    <Common.Box
                      fontSize={3}
                      lineHeight="1.4"
                      py={0}
                      fontFamily="head"
                    >
                      {win.gameName}
                    </Common.Box>
                    <Common.Box fontSize={1} fontFamily="head">
                      {translate('big-winners.initial', {
                        displayName: win.displayName,
                      })}
                    </Common.Box>
                  </Common.Box>
                </Common.Box>
              ),
              bigWins
            )}
          </Common.Box>
        </Common.Box>

        <Common.Box py={[2, 4]}>
          <InformativeSection />
        </Common.Box>

        <Common.Box p={2} textAlign="left" fontSize={2}>
          <HtmlContent
            html={{
              __html: translate('landing-page.seo-content'),
            }}
          />
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-page.welcome-offer-background',
    'landing-page.welcome-offer-image',
    'landing-page.welcome-offer-image-mobile',
    'landing-page.header-info',
    'landing-page.login-link',
    'landing-page.register',
    'landing-page.header-welcome-offer-info',
    'big-winners.header',
    'big-winners.initial',
    'landing-page.seo-content',
  ],
  LandingPage
)

// for @loadable/components
export default LandingPage
