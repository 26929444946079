import * as React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import { Amount } from './amount'
import { withNumberEasing } from './with-number-easing'

const NumberEasedAmount = withNumberEasing(Amount, {
  propName: 'children',
  easing: 'outQuint',
  speed: 5000,
  delay: 500,
})

function makeItRain() {
  const canvas = document.querySelector('#cash-moment')
  const ctx = canvas.getContext('2d')

  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
  canvas.id = 'cash-moment'

  const coin = new Image()
  coin.src = '/images/coin-sprite.png'
  // 440 wide, 40 high, 10 states

  coin.onload = () => {
    drawloop()
  }
  const coins = []

  function drawloop() {
    requestAnimationFrame(drawloop)

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    if (Math.random() < 0.3) {
      coins.push({
        x: (Math.random() * canvas.width) | 0,
        y: -50,
        dy: 3,
        s: 0.5 + Math.random(),
        state: (Math.random() * 10) | 0,
      })
    }
    let i = coins.length

    while (i--) {
      const x = coins[i].x
      const y = coins[i].y
      const s = coins[i].s
      const state = coins[i].state
      coins[i].state = state > 9 ? 0 : state + 0.3
      coins[i].dy += 0.3
      coins[i].y += coins[i].dy

      ctx.drawImage(
        coin,
        44 * Math.floor(state),
        0,
        44,
        40,
        x,
        y,
        44 * s,
        40 * s
      )

      if (y > canvas.height) {
        coins.splice(i, 1)
      }
    }
  }
}

export function CashMoment(props) {
  const translate = Herz.I18n.useTranslate()

  const [amount, setAmount] = React.useState(
    props.balance - props.claimedCashback
  )
  const [cashback, setCashback] = React.useState(props.claimedCashback)

  React.useEffect(() => {
    makeItRain()
    setAmount(props.balance)
    setCashback(0)
  }, [])

  return (
    <React.Fragment>
      <canvas id="cash-moment" />
      <Common.Box
        position="fixed"
        width="100%"
        height="100%"
        left="0px"
        top="0px"
        fontSize={['60px', '80px']}
        color="g-text"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight="bold"
        style={{ fontVariantNumeric: 'tabular-nums' }}
        fontFamily="Helvetica"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Common.Box display="grid" textAlign="center" gridRowGap={4}>
            <Common.Box display="grid" gridRowGap={1}>
              <Common.Text fontSize={2}>
                {translate('cash-moment.balance')}
              </Common.Text>
              <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: 1.3 }}
                transition={{ duration: 4.5, delay: 0.5 }}
              >
                <NumberEasedAmount decimals="show">{amount}</NumberEasedAmount>
              </motion.div>
            </Common.Box>
            <Common.Box display="grid" gridRowGap={1}>
              <Common.Text fontSize={2}>
                {translate('cash-moment.cashback')}
              </Common.Text>
              <motion.div
                initial={{ scale: 1, opacity: 1 }}
                animate={{ scale: 0.7, opacity: 0.6 }}
                transition={{ duration: 4.5, delay: 0.5 }}
              >
                <NumberEasedAmount decimals="show">
                  {cashback}
                </NumberEasedAmount>
              </motion.div>
            </Common.Box>
            <Common.Box fontSize={1} height="14px">
              {props.dismissable && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.8 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {translate('cash-moment.dismiss')}
                </motion.div>
              )}
            </Common.Box>
          </Common.Box>
        </motion.div>
      </Common.Box>
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  ['cash-moment.balance', 'cash-moment.cashback', 'cash-moment.dismiss'],
  CashMoment
)

CashMoment.propTypes = {
  balance: PropTypes.number,
  claimedCashback: PropTypes.number,
  dismissable: PropTypes.bool,
}
