import * as EmotionTheming from 'emotion-theming'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from './configuration'
import { DynamicTranslationImage } from './dynamic-image'

const ImageWrapper = styled.div`
  ${css({
    position: ['relative', 'absolute'],
    right: '0px',
    top: '0px',
    transform: [null, 'translateY(-50%)'],
  })}
`

const items = [1, 2, 3, 4]

export function WelcomeOfferExplanation() {
  const translate = Herz.I18n.useTranslate()
  const theme = EmotionTheming.useTheme()
  const hasWebpSupport = ReactRedux.useSelector(state =>
    Configuration.getHasWebpSupport(state.configuration)
  )

  return (
    <Common.Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      px={[null, 1]}
    >
      {items.map(item => (
        <React.Fragment key={item}>
          <Common.Box
            border="2px solid white"
            borderRadius="8px"
            boxShadow={`0px 0px 6px ${theme.colors['welcome-offer-shadow']}`}
            style={{ transform: 'skewX(-10deg)' }}
            width={['100%', '49%', null, '24.5%']}
            backgroundImage={`linear-gradient(
                360deg,
                ${theme.colors['welcome-offer-gradient-start']} 0%,
                ${theme.colors['welcome-offer-gradient-stop']} 100%
              )`}
            mt={1}
          >
            <Common.Box
              style={{ transform: 'skewX(10deg)' }}
              position="relative"
              padding={[1, 2]}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Common.Box>
                <Common.Box
                  fontFamily="head"
                  fontSize={[2, 3]}
                  lineHeight="1.375"
                >
                  {translate(`welcome-offer-explanation.title-${item}`)}
                </Common.Box>
                <Common.Box
                  fontFamily="head"
                  fontSize={[3, 5]}
                  fontWeight="800"
                  lineHeight="1.3"
                  pt={[0, 1]}
                >
                  {translate(`welcome-offer-explanation.body-${item}`)}
                </Common.Box>
              </Common.Box>
              <ImageWrapper>
                <DynamicTranslationImage
                  source={translate(`welcome-offer-explanation.image-${item}`)}
                  alt={`welcome-offer-${item}`}
                  resizingWidth={['70', '100']}
                  height={['63px', '70px', null, '76px']}
                  extension={hasWebpSupport ? 'webp' : 'png'}
                />
              </ImageWrapper>
            </Common.Box>
          </Common.Box>
          <Common.Box
            width="100%"
            display={[item === 2 ? 'flex' : 'none', null, null, 'none']}
            alignItems="center"
            justifyContent="center"
            pt={1}
          >
            <Common.Box as="img" src="/images/arrow.svg" />
            <Common.Box fontSize={2} px={2}>
              {translate('welcome-offer-explanation.side-note')}
            </Common.Box>
            <Common.Box as="img" src="/images/arrow.svg" />
          </Common.Box>
        </React.Fragment>
      ))}
      <Common.Box
        width="50%"
        marginLeft="50%"
        display={['none', null, null, 'flex']}
        justifyContent="flex-end"
        alignItems="center"
        pr={1}
        pt={1}
      >
        <Common.Box flexGrow="1" borderBottom="1px solid white" />
        <Common.Box fontSize={1} px={2}>
          {translate('welcome-offer-explanation.side-note')}
        </Common.Box>
        <Common.Box flexGrow="1" borderBottom="1px solid white" />
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    ...items.flatMap(item => [
      `welcome-offer-explanation.title-${item}`,
      `welcome-offer-explanation.body-${item}`,
      `welcome-offer-explanation.image-${item}`,
    ]),
    'welcome-offer-explanation.side-note',
  ],
  WelcomeOfferExplanation
)
