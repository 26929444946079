const dictionary = {
  ａ: 'a',
  Ａ: 'A',
  ｂ: 'b',
  Ｂ: 'B',
  ｃ: 'c',
  Ｃ: 'C',
  ｄ: 'd',
  Ｄ: 'D',
  ｅ: 'e',
  Ｅ: 'E',
  ｆ: 'f',
  Ｆ: 'F',
  ｇ: 'g',
  Ｇ: 'G',
  ｈ: 'h',
  Ｈ: 'H',
  ｉ: 'i',
  Ｉ: 'I',
  ｊ: 'j',
  Ｊ: 'J',
  ｋ: 'k',
  Ｋ: 'K',
  ｌ: 'l',
  Ｌ: 'L',
  ｍ: 'm',
  Ｍ: 'M',
  ｎ: 'n',
  Ｎ: 'N',
  ｏ: 'o',
  Ｏ: 'O',
  ｐ: 'p',
  Ｐ: 'P',
  ｑ: 'q',
  Ｑ: 'Q',
  ｒ: 'r',
  Ｒ: 'R',
  ｓ: 's',
  Ｓ: 'S',
  ｔ: 't',
  Ｔ: 'T',
  ｕ: 'u',
  Ｕ: 'U',
  ｖ: 'v',
  Ｖ: 'V',
  ｗ: 'w',
  Ｗ: 'W',
  ｘ: 'x',
  Ｘ: 'X',
  ｙ: 'y',
  Ｙ: 'Y',
  ｚ: 'z',
  Ｚ: 'Z',
  '　': ' ',
  '－': '-',
  '＋': '+',
  '％': '%',
  '￥': '\\',
  '＠': '@',
  '／': '/',
  '＇': "'",
  '！': '!',
  '＃': '#',
  '＄': '$',
  '＾': '^',
  '？': '?',
  '：': ':',
  '，': ',',
  '（': '(',
  '）': ')',
  '｛': '{',
  '｝': '}',
  '［': '[',
  '］': ']',
  '～': '~',
  '｀': '`',
  '＿': '_',
  '．': '.',
  '＊': '*',
  '＝': '=',
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
}

//TODO: move to @rushplay/herz
export function fullWidthToHalfWidth(value) {
  if (!value) {
    return undefined
  }

  return value
    .split('')
    .map(char => dictionary[char] || char)
    .join('')
}
